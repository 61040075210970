import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Urls } from 'src/app/models/urls';
import { Variables } from 'src/app/models/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class RecipeService {

  url = new Urls();
  dcmApiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.dcmApiUrl = this.url.dcmApiUrl;
  }

  getRecipes(page, size, rcpId, rcpName) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8).toString();

    const orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.dcmApiUrl + '/api/recipe/' + page + '/' + size + '/' + orgId + '/' + rcpId + '/' + rcpName + '/ /';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
