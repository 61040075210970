import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Urls } from 'src/app/models/urls';

@Injectable({
  providedIn: 'root'
})
export class StepService {

  url = new Urls();
  dcmApiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.dcmApiUrl = this.url.dcmApiUrl;
  }

  getSteps(page, size, rcpId, stpNr) {
    // tslint:disable-next-line: max-line-length
    const url = this.dcmApiUrl + '/api/step/' + page + '/' + size + '/' + rcpId + '/' + stpNr + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
