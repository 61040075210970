import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Urls } from 'src/app/models/urls';
import { tap, catchError } from 'rxjs/operators';
import { Category } from 'src/app/models/category';
import { Int64Updater } from 'src/app/models/key-value';
import { Variables } from 'src/app/models/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getCategories(page, size, catId, groups) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8).toString();

    const orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/category/' + page + '/' + size + '/' + orgId + '/' + catId + '/' + groups + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postCategory(cat: Category) {
    return this.httpclient.post(this.apiUrl + '/api/category', cat)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putCategory(val: Int64Updater) {
    return this.httpclient.put(this.apiUrl + '/api/category/' + val.Id, val)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteCategory(id: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete<any>(this.apiUrl + '/api/category/' + id, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
