export class Params {
    public Type: string;
    public Id: number;
    public Name: string;
    public Desc: string;
    public Value: number;
    public NewValue: string;
    public Mandatory: boolean;
    public FileName: string;
    public FileComment: string;
    public StepNr: number;
    public IsVisible = false;
}
