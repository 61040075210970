import { SetupPermission } from './../../models/setup-permission';
import { GroupService } from './../../services/group/group.service';
import { Category } from './../../models/category';
import { Setup } from './../../models/setup';
import { RecipeService } from './../../services/recipe/recipe.service';
import { KeyValue } from './../../models/key-value';
import { SetupPermsService } from './../../services/setup-permission/setup-perms.service';
import { SetupService } from './../../services/setup/setup.service';
import { CategoryService } from './../../services/category/category.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Int64Updater } from 'src/app/models/key-value';
import { Variables } from 'src/app/models/variables';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {

  categories = [];
  setups = [];
  permissions = [];
  recipes = [];
  groups = [];

  permgroups = [];

  recipeName = '';
  setupName = 'Setup';
  cancelText = 'Cancel';
  createText = 'Create';
  saveText = 'Save';
  yesText = 'Yes';
  noText = 'No';

  cloadmore = false;
  sloadmore = false;

  iscategory = false;
  issetup = false;
  ispermission = false;
  iseditperm = false;

  isclose = true;
  isopt = false;

  cpage = 1;
  spage = 1;
  size = 100;

  cat: any = [];
  set: any = [];

  catid = 0;
  catname: string;
  catdesc: string;

  setupid = 0;
  setupname: string;
  setupdesc: string;
  recipe: any;

  iscreatecategory = false;
  iscreatesetup = false;
  isaddperm = false;

  isclickshoweditpermission = true;
  isclickcloseeditpermission = false;

  iseditcategory = false;
  iseditsetup = false;

  loading = false;

  createcat = false;
  createset = false;
  createperm = false;
  editcat = false;
  editset = false;
  editperm = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private catService: CategoryService,
    private setService: SetupService,
    private permService: SetupPermsService,
    private messageService: MessageService,
    private recipeService: RecipeService,
    private groupService: GroupService
  ) {
     this.translate.addLangs(['English', 'French']);
     this.translate.setDefaultLang('English');

     let browserLang = this.translate.getBrowserLang();

     const pass = 'ranitessarldev2019';
     const lang = cookieService.get('goepla-portal-language');
     if (lang.trim() !== '') {
       const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
       const lg = declang;
       browserLang = lg;
       localStorage.setItem('language', lg);
     }

     if (localStorage.getItem('language') !== null) {
       browserLang = localStorage.getItem('language');
     }
     this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

     this.translate.get('GENERAL.CANCEL').subscribe((resp: string) => {
       this.cancelText = resp;
     });

     this.translate.get('GENERAL.CREATE').subscribe((resp: string) => {
      this.createText = resp;
     });

     this.translate.get('GENERAL.SAVE').subscribe((resp: string) => {
      this.saveText = resp;
     });

     this.translate.get('GENERAL.SETUP').subscribe((resp: string) => {
        this.setupName = resp;
     });

     this.translate.get('GENERAL.YES').subscribe((resp: string) => {
      this.yesText = resp;
     });

     this.translate.get('GENERAL.NO').subscribe((resp: string) => {
        this.noText = resp;
     });
   }

  ngOnInit() {
    const v = new Variables();
    const va = localStorage.getItem('ups');
    const perm = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);

    if ((perm !== undefined && perm !== null && perm.includes('DCT.Administration.Category'))
    || (perm !== undefined && perm !== null && perm.includes('DCT.Administration.Full'))) {
      this.createcat = true;
      this.editcat = true;
    }
    if ((perm !== undefined && perm !== null && perm.includes('DCT.Administration.Setup'))
    || (perm !== undefined && perm !== null && perm.includes('DCT.Administration.Full'))) {
      this.createset = true;
      this.editset = true;
    }
    if ((perm !== undefined && perm !== null && perm.includes('DCT.Administration.SetupPermission'))
    || (perm !== undefined && perm !== null && perm.includes('DCT.Administration.Full'))) {
      this.createperm = true;
      this.editperm = true;
    }
    this.getCategories();
  }

  async getCategories() {
    this.loading = true;
    const cats = await this.catService.getCategories(this.cpage, this.size, 0, ' ').toPromise().catch(err => this.handleError(err));
    if (cats != null && cats.length > 0) {
      if (cats.length >= this.size) {
        this.cloadmore = true;
      } else {
        this.cloadmore = false;
      }

      for (const cat of cats) {
        cat.color = 'transparent';
        this.categories.push(cat);
      }
    } else {
      this.cloadmore = false;
    }

    this.iscategory = true;
    this.loading = false;
  }

  async getSetups() {
    this.loading = true;
    const sets = await this.setService.getSetups(this.spage, this.size, this.cat.cat_id, 0).toPromise().catch(err => this.handleError(err));
    if (sets != null && sets.length > 0) {
      if (sets.length >= this.size) {
        this.sloadmore = true;
      } else {
        this.sloadmore = false;
      }

      for (const set of sets) {
        set.color = 'transparent';
        this.setups.push(set);
      }
    } else {
      this.sloadmore = false;
    }
    this.issetup = true;
    this.loading = false;
  }

  async getPermissions(setupId) {
    this.loading = true;
    this.permgroups = [];
    const perms = await this.permService.getSetupPermissions(0, 0, setupId).toPromise().catch(err => this.handleError(err));
    if (perms != null && perms.length > 0) {
      for (const perm of perms) {
        this.permissions.push(perm);
        this.permgroups.push(perm.group);
      }
    } else {
    }
    this.ispermission = true;
    this.loading = false;
  }

  async getRecipes(rname: string) {
    this.loading = true;
    this.recipes = [];
    const reps = await this.recipeService.getRecipes(0, 0, 0, ' ').toPromise().catch(err => this.handleError(err));
    if (reps != null && reps.length > 0) {
      for (const rep of reps) {
        this.recipes.push({ label: rep.rcp_name, value: rep});
        if (rname !== undefined && rname != null) {
          if (rep.rcp_name === rname) {
            this.recipe = rep;
          }
        }
      }
    } else {
    }
    this.loading = false;
  }

  async getGroups() {
    this.loading = true;
    const grps = [];
    let run = true;
    let page = 0;

    while (run === true) {
      page += 1;
      const gps = await this.groupService.getGroups(page, this.size).toPromise().catch(err => this.handleError(err));
      if (gps != null && gps.length > 0) {
        for (const gp of gps) {
          if (!this.permgroups.includes(gp.groupName)) {
            // grps.push({ label: gp.groupName, value: gp});
            grps.push(gp);
          }
        }
      } else {
        run = false;
      }
    }
    this.groups = grps;
    this.loading = false;
  }

  catClicked(cat) {
    this.spage = 1;
    this.setups = [];
    this.permissions = [];
    this.ispermission = false;
    this.issetup = false;
    this.cat = cat;
    for (const ccat of this.categories) {
      if (cat.cat_id === ccat.cat_id) {
        ccat.color = 'lightgray';
      } else {
        ccat.color = 'transparent';
      }
    }
    this.getSetups();
  }

  setClicked(set) {
    this.isclickcloseeditpermission = false;
    this.isclickshoweditpermission = true;
    this.iseditperm = false;
    this.set = set;
    this.ispermission = false;
    this.permissions = [];
    this.recipeName = set.rcp_name;
    this.translate.get('GENERAL.DETAILSFORSETUP').subscribe((resp: string) => {
      this.setupName = resp + ' - ' + set.setup_name;
    });
    for (const sset of this.setups) {
      if (set.setup_id === sset.setup_id) {
        sset.color = 'lightgray';
      } else {
        sset.color = 'transparent';
      }
    }
    this.getPermissions(set.setup_id);
  }

  editSetupPerm() {
    this.iseditperm = true;
    this.isclickcloseeditpermission = true;
    this.isclickshoweditpermission = false;
  }

  closeSetupPerm() {
    this.isclickcloseeditpermission = false;
    this.isclickshoweditpermission = true;
    this.iseditperm = false;
  }

  loadMoreC() {
    this.cpage += 1;
    this.getCategories();
  }

  loadMoreS() {
    this.spage += 1;
    this.getSetups();
  }

  showAddCategory() {
    this.iscreatecategory = true;
  }

  async showAddSetup() {
    await this.getRecipes(null);
    this.iscreatesetup = true;
  }

  async showAddPerm() {
    await this.getGroups();
    this.isaddperm = true;
  }

  showEditCat(cat) {
    this.catid = cat.cat_id;
    this.catname = cat.cat_name;
    this.catdesc = cat.cat_desc;
    this.iseditcategory = true;
  }

  async showEditSetup(set) {
    this.setupid = set.setup_id;
    this.setupname = set.setup_name;
    this.setupdesc = set.setup_desc;
    await this.getRecipes(set.rcp_name);
    this.iseditsetup = true;
  }

  async createCategory() {
    this.loading = true;
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8).toString();

    const orgId = +oi;

    if (this.catname === undefined || this.catname === null || this.catname.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERCATNAME').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
      this.loading = false;
    } else if (this.catdesc === undefined || this.catdesc === null || this.catdesc.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERCATDESC').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
      this.loading = false;
    } else {
      const cat = new Category();
      cat.org_id = orgId;
      cat.cat_name = this.catname;
      cat.cat_desc = this.catdesc;

      const res = await this.catService.postCategory(cat).toPromise().catch(err => this.handleError(err));
      if (res > 0) {
        // success
        this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
          this.translate.get('GENERAL.CATEGORYCREATED').subscribe((resp1: string) => {
            this.showSuccess(resp, resp1);
          });
        });
      } else {
        // failure
        this.translate.get('GENERAL.FAILED').subscribe((resp: string) => {
          this.translate.get('GENERAL.CATEGORYNOTCREATED').subscribe((resp1: string) => {
            this.showError(resp, resp1);
          });
        });
      }

      this.loading = false;
      this.cancel();
      this.cat = [];
      this.set = [];
      this.translate.get('GENERAL.SETUP').subscribe((resp: string) => {
        this.setupName = resp;
      });
      this.categories = [];
      this.setups = [];
      this.permissions = [];
      this.issetup = false;
      this.ispermission = false;
      this.cpage = 1;
      this.spage = 1;
      await this.getCategories();
    }
  }

  async createSetup() {
    this.loading = true;

    if (this.recipe === undefined || this.recipe === null) {
      this.translate.get('GENERAL.SELECTRECIPE').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
      this.loading = false;
    } else if (this.setupname === undefined || this.setupname === null || this.setupname.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERSETUPNAME').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
      this.loading = false;
    } else if (this.setupdesc === undefined || this.setupdesc === null || this.setupdesc.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERSETUPDESC').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
      this.loading = false;
    } else {
      const set = new Setup();
      set.cat_id = this.cat.cat_id;
      set.setup_name = this.setupname;
      set.setup_desc = this.setupdesc;
      set.rcp_name = this.recipe.rcp_name;

      const res = await this.setService.postSetup(set).toPromise().catch(err => this.handleError(err));
      if (res > 0) {
        // success
        this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
          this.translate.get('GENERAL.SETUPCREATED').subscribe((resp1: string) => {
            this.showSuccess(resp, resp1);
          });
        });
      } else {
        // failure
        this.translate.get('GENERAL.FAILED').subscribe((resp: string) => {
          this.translate.get('GENERAL.SETUPNOTCREATED').subscribe((resp1: string) => {
            this.showError(resp, resp1);
          });
        });
      }

      this.loading = false;
      this.cancel();
      this.setups = [];
      this.permissions = [];
      this.set = [];
      this.translate.get('GENERAL.SETUP').subscribe((resp: string) => {
        this.setupName = resp;
      });
      this.ispermission = false;
      this.spage = 1;
      await this.getSetups();
    }
  }

  async saveCategory() {
    this.loading = true;

    if (this.catname === undefined || this.catname === null || this.catname.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERCATNAME').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
      this.loading = false;
    } else if (this.catdesc === undefined || this.catdesc === null || this.catdesc.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERCATDESC').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
      this.loading = false;
    } else {
      const val = new Int64Updater();
      val.Id = this.catid;
      val.Values = [];

      const name = new KeyValue();
      name.key = 'name';
      name.Value = this.catname;
      val.Values.push(name);

      const desc = new KeyValue();
      desc.key = 'desc';
      desc.Value = this.catdesc;
      val.Values.push(desc);

      const res = await this.catService.putCategory(val).toPromise().catch(err => this.handleError(err));

      if (res === true) {
        // success
        this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
          this.translate.get('GENERAL.CATEGORYUPDATED').subscribe((resp1: string) => {
            this.showSuccess(resp, resp1);
          });
        });
      } else {
        // failure
        this.translate.get('GENERAL.FAILED').subscribe((resp: string) => {
          this.translate.get('GENERAL.CATEGORYNOTUPDATED').subscribe((resp1: string) => {
            this.showError(resp, resp1);
          });
        });
      }

      this.loading = false;
      this.cancel();
      this.categories = [];
      this.setups = [];
      this.permissions = [];
      this.cat = [];
      this.set = [];
      this.translate.get('GENERAL.SETUP').subscribe((resp: string) => {
        this.setupName = resp;
      });
      this.issetup = false;
      this.ispermission = false;
      this.cpage = 1;
      this.spage = 1;
      await this.getCategories();
    }
  }

  async saveSetup() {
    this.loading = true;

    if (this.recipe === undefined || this.recipe === null) {
      this.translate.get('GENERAL.SELECTRECIPE').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
      this.loading = false;
    } else if (this.setupname === undefined || this.setupname === null || this.setupname.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERSETUPNAME').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
      this.loading = false;
    } else if (this.setupdesc === undefined || this.setupdesc === null || this.setupdesc.toString().trim() === '') {
      this.translate.get('GENERAL.ENTERSETUPDESC').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
      this.loading = false;
    } else {
      const val = new Int64Updater();
      val.Id = this.setupid;
      val.Values = [];

      const name = new KeyValue();
      name.key = 'name';
      name.Value = this.setupname;
      val.Values.push(name);

      const desc = new KeyValue();
      desc.key = 'desc';
      desc.Value = this.setupdesc;
      val.Values.push(desc);

      const rep = new KeyValue();
      rep.key = 'recipe';
      rep.Value = this.recipe.rcp_name;
      val.Values.push(rep);

      const res = await this.setService.putSetup(val).toPromise().catch(err => this.handleError(err));
      if (res === true) {
        // success
        this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
          this.translate.get('GENERAL.SETUPUPDATED').subscribe((resp1: string) => {
            this.showSuccess(resp, resp1);
          });
        });
      } else {
        // failure
        this.translate.get('GENERAL.FAILED').subscribe((resp: string) => {
          this.translate.get('GENERAL.SETUPUPNOTDATED').subscribe((resp1: string) => {
            this.showError(resp, resp1);
          });
        });
      }

      this.loading = false;
      this.cancel();
      this.setups = [];
      this.permissions = [];
      this.set = [];
      this.translate.get('GENERAL.SETUP').subscribe((resp: string) => {
        this.setupName = resp;
      });
      this.ispermission = false;
      this.spage = 1;
      await this.getSetups();
    }
  }

  showDeleteSetupPerm(perm) {
    this.isclose = false;
    this.isopt = true;
    this.translate.get('GENERAL.DELETE').subscribe((text: string) => {
      this.confirm(text + ' ' + perm.group + '?', 'deletesetuppermission', perm);
    });
  }

  async deleteSetupPerm(perm) {
    this.loading = true;
    const res = await this.permService.deleteSetupPermission(perm).toPromise().catch(err => this.handleError(err));
    if (res === true) {
      // success
      this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
        this.translate.get('GENERAL.PERMISSIONDELETED').subscribe((resp1: string) => {
          this.showSuccess(resp, resp1);
        });
      });
    } else {
      // failure
      this.translate.get('GENERAL.FAILED').subscribe((resp: string) => {
        this.translate.get('GENERAL.PERMISSIONNOTDELETED').subscribe((resp1: string) => {
          this.showError(resp, resp1);
        });
      });
    }
    this.loading = false;
    this.permissions = [];
    this.getPermissions(perm.setup);
  }

  async addPermission(group) {
    const perm = new SetupPermission();
    perm.Group = group.groupName;
    perm.Setup = this.set.setup_id;

    this.loading = true;
    const res = await this.permService.postSetupPermissoin(perm).toPromise().catch(err => this.handleError(err));
    if (res === true) {
      // success
      this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
        this.translate.get('GENERAL.PERMISSIONADDED').subscribe((resp1: string) => {
          this.showSuccess(resp, resp1);
        });
      });
    } else {
      // failure
      this.translate.get('GENERAL.FAILED').subscribe((resp: string) => {
        this.translate.get('GENERAL.PERMISSIONNOTADDED').subscribe((resp1: string) => {
          this.showError(resp, resp1);
        });
      });
    }
    this.loading = false;
    this.permissions = [];
    this.isaddperm = false;
    this.getPermissions(this.set.setup_id);
  }

  confirm(text, operation, value) {
    this.confirmationService.confirm({
        message: text,
        accept: async () => {
            // Accept logic
            if (operation === 'deletesetuppermission') {
              await this.deleteSetupPerm(value);
            }
            this.isopt = false;
            this.isclose = true;
        },
        reject: () => {
          // Reject logic
          this.isopt = false;
          this.isclose = true;
        }
    });
  }

  cancel() {
    this.iscreatesetup = false;
    this.iscreatecategory = false;
    this.iseditcategory = false;
    this.iseditsetup = false;
    this.catname = null;
    this.catdesc = null;
    this.setupname = null;
    this.setupdesc = null;
    this.recipe = null;
  }

  showSuccess(title, message) {
    this.messageService.add({ key: 'tc', severity: 'success', summary: title, detail: message });
  }

  showInfo(title, message) {
      this.messageService.add({ key: 'tc', severity: 'info', summary: title, detail: message });
  }

  showWarn(title, message) {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: title, detail: message });
  }

  showError(title, message) {
      this.messageService.add({ key: 'tc', severity: 'error', summary: title, detail: message });
  }

  handleError(err) {
    this.loading = false;
    this.isopt = false;
    this.isclose = true;
    if (err != null && err.error != null
       && err.error.MessageText === 'duplicate key value violates unique constraint "t_dct_cat_cat_name_key"') {
      this.translate.get('GENERAL.CATEGORYALREADYEXISTS').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else if (err != null && err.error != null
       && err.error.MessageText === 'duplicate key value violates unique constraint "t_dct_setup_setup_name_key"') {
      this.translate.get('GENERAL.SETUPALREADYEXISTS').subscribe((resp: string) => {
        this.confirm(resp, null, null);
      });
    } else {
      console.log(err);
    }
  }

}
