export class Session {
    // tslint:disable-next-line: variable-name
    public org_id: number;
    // tslint:disable-next-line: variable-name
    public sess_id: string = null;
    // tslint:disable-next-line: variable-name
    public rcp_id: number;
    public res: string; // ('P','F','-')), -- Pass; Fail
    // tslint:disable-next-line: variable-name
    public sess_start: Date;
    // tslint:disable-next-line: variable-name
    public sess_end: Date;
    // tslint:disable-next-line: variable-name
    public sess_ext_id1: string = null;
    // tslint:disable-next-line: variable-name
    public sess_ext_id2: string = null;
    // tslint:disable-next-line: variable-name
    public sess_ext_id3: string = null;
    // tslint:disable-next-line: variable-name
    public sess_ext_id4: string = null;
    // tslint:disable-next-line: variable-name
    public sess_ext_id5: string = null;
    public oprator: string = null;
}

export class SessionText {
    // tslint:disable-next-line: variable-name
    public sess_id: string = null;
    // tslint:disable-next-line: variable-name
    public step_nr = 0;
    // tslint:disable-next-line: variable-name
    public txt_id: string;
    // tslint:disable-next-line: variable-name
    public txt_val: string;
}

export class SessionValue {
    // tslint:disable-next-line: variable-name
    public sess_id: string = null;
    // tslint:disable-next-line: variable-name
    public step_nr = 0;
    public val = 0;
    public res: string; // ('P','F')), -- Pass; Fail
}
