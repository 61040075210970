export class SessionFile {
    // tslint:disable-next-line: variable-name
    public sess_id: string;
    // tslint:disable-next-line: variable-name
    public step_nr: number;
    // tslint:disable-next-line: variable-name
    public sess_file_id: string;
    // tslint:disable-next-line: variable-name
    public sess_filename: string;
    // tslint:disable-next-line: variable-name
    public file_cmt: string;
    // tslint:disable-next-line: variable-name
    public flm_file_id: string;
}
