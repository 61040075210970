import { GroupService } from './services/group/group.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { MenuItem, ConfirmationService } from 'primeng/api';
import * as CryptoJS from 'crypto-js';
import { PermissionService } from './services/permission/permission.service';
import { Variables } from './models/variables';
import { UserService } from './services/user/user.service';
import { KeycloakService } from 'keycloak-angular';
import { FileService } from './services/file/file.service';
import { LanguageService } from './services/language/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  items: MenuItem[] = [];
  load = false;

  adminText = 'Administration';
  dataText = 'Data collection';

  loading = false;
  uid = 0;

  appicon: HTMLLinkElement = document.querySelector('#appicon');

  infoText = 'Information';
  closeText = 'Close';

  constructor(
    private confirmationService: ConfirmationService,
    public translate: TranslateService,
    private cookieService: CookieService,
    private router: Router,
    private groupsService: GroupService,
    private permService: PermissionService,
    private usersService: UserService,
    private keycloakService: KeycloakService,
    private fileService: FileService,
    private language: LanguageService
    ) {
      if ('serviceWorker' in navigator) {
        caches.keys().then(function(cacheNames) {
          cacheNames.forEach(function(cacheName) {
            caches.delete(cacheName);
          });
        });
      }
  
    this.router.navigateByUrl('');

    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';

    // test values
    /*this.cookieService.set('goepla-portal-user-id', CryptoJS.AES.encrypt('47', pass).toString(),
                           1000, '/', 'localhost', false, 'Lax');
    this.cookieService.set('goepla-portal-organisation-id', CryptoJS.AES.encrypt('1', pass).toString(),
                           1000, '/', 'localhost', false, 'Lax');*/

    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');


    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.DATACOLLECTION').subscribe((resp: string) => {
      this.dataText = resp;
    });
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ADMINISTRATION').subscribe((resp: string) => {
      this.adminText = resp;
    });
    this.loading = true;
  }

  async ngOnInit() {
    // if (this.load === false) {
    //   return;
    // }
    this.load = true;

    if (localStorage.getItem('browser') === null || localStorage.getItem('browser') === 'false') {
      await this.showInfo('browser');
      return;
    }

    await this.setIcon();
    await this.getUser();
  }

  async setIcon() {
    this.loading = true;
    const file = await this.fileService.getFiles(1, 1, ' ', 'dct-icon', 'dct-bucket', ' ', ' ')
    .toPromise().catch(err => this.handleError(err));
    if (file !== null && file.length > 0) {
      const furl = await this.fileService.getFile('dct-bucket', file[0].fileId)
      .toPromise().catch(err => this.handleError(err));
      if (furl !== null && furl.url !== null) {
        this.appicon.href = furl.url;
      }
    }
    this.loading = false;
  }

  async getUser() {
    this.loading = true;
    const eml = (await this.keycloakService.loadUserProfile()).email;
    const ress = await this.usersService.getUserInfo(0, 0, eml, null).toPromise().catch(err => this.handleError(err));

    if (ress !== undefined && ress !== null && ress.userId > 0) {
      this.uid = ress.userId;
      const v = new Variables();
      const oid = CryptoJS.AES.encrypt(JSON.stringify(ress.organisationId), v.pass).toString();
      const uid = CryptoJS.AES.encrypt(JSON.stringify(ress.userId), v.pass).toString();
      localStorage.setItem('oi', oid);
      localStorage.setItem('ui', uid);

      await this.showInfo('null');
      await this.getUserGroups(this.uid);
    } else {
      this.loading = false;
      this.load = false;
      // this.router.navigateByUrl('/unauthorizeduser');
      await this.showInfo('user');
    }
  }

  async getUserGroups(uid) {
    this.loading = true;
    let mygroups = '';
    let groupids = '';
    const usergroups = await this.groupsService.getUserGroups(0, uid).toPromise()
                                                .catch(err => this.handleError(err));

    if (usergroups != null && usergroups.length > 0) {
      for (const val of usergroups) {
        groupids += val.groupId + ':';
        const group = await this.groupsService.getGroupById(val.groupId).toPromise()
                                              .catch(err => this.handleError(err));
        if (group != null && group.length > 0) {
          mygroups += group[0].groupName + ':';
        }
      }
    }

    const user = await this.usersService.getUserInfo(uid, 0, ' ', ' ').toPromise()
    .catch(err => this.handleError(err));

    const v = new Variables();
    const gs = CryptoJS.AES.encrypt(mygroups, v.pass);
    localStorage.setItem('mg', gs);
    // localStorage.setItem('my-groups', mygroups);
    // localStorage.setItem('user-group-ids', groupids);

    await this.getPermissions(groupids, user.organisationName, mygroups);

    this.loading = false;
    // localStorage.setItem('organisation-id', '1');
    // localStorage.setItem('my-groups', 'Goepla group');
    // this.router.navigateByUrl('select-category');
  }

  async getPermissions(groupids, org, mygroups) {
    this.loading = true;
    const permissions = [];
    // const groupids = localStorage.getItem('user-group-ids');
    if (groupids != null) {
      const gids = groupids.split(':');
      if (gids !== undefined && gids !== null && gids.length > 0) {
        for (const id of gids) {
          if (id !== '') {
            const perms = await this.permService.getUserPermissions(0, id, ' ').toPromise()
            .catch(err => this.handleError(err));
            if (perms != null && perms.length > 0) {
              for (const perm of perms) {
                // localStorage.setItem(perm.perm, perm.perm);
                permissions.push(perm.perm);
              }
            }
          }
        }
      }
    }

    if (org === 'ORG_SUPER_ADMIN' || mygroups.includes('Administrators-Full_Group')) {
      permissions.push('DCT.Administration.Full');
    }

    const v = new Variables();
    const p = CryptoJS.AES.encrypt(JSON.stringify(permissions), v.pass).toString();
    localStorage.setItem('ups', p);

    await this.buildMenu(permissions);
    this.loading = false;
  }

  async buildMenu(perm) {
    if (perm != null && perm.length > 0) {
      let isdata = false;
    //  if (perm.includes('DCT.Section.DataCollection') || perm.includes('DCT.DataCollection.Category')
    //   || perm.includes('DCT.DataCollection.Setup') || perm.includes('DCT.DataCollection.Session')
    //   || perm.includes('DCT.Administration.Full')) {
      if (perm.includes('DCT.Section.DataCollection') || perm.includes('DCT.Administration.Full')) {
        isdata = true;
        this.items.push({
          label: this.dataText,
          id: 'datacollection',
          routerLink: ['/select-category']
        });
      }

      let isadmin = false;
    //  if (perm.includes('DCT.Section.Administration') || perm.includes('DCT.Administration.Category')
    //   || perm.includes('DCT.Administration.Setup') || perm.includes('DCT.Administration.SetupPermission')
    //   || perm.includes('DCT.Administration.Full')) {
      if (perm.includes('DCT.Section.Administration') || perm.includes('DCT.Administration.Full')) {
        isadmin = true;
        this.items.push({
          label: this.adminText,
          id: 'administration',
          routerLink: ['/administration']
        });
      }

      if (isdata === true) {
        this.router.navigateByUrl('/select-category');
      } else if (isadmin === true) {
        this.router.navigateByUrl('/administration');
      }

      if (this.items.length === 0) {
        this.router.navigateByUrl('/unauthorized-user');
        this.load = false;
      }
    } else {
      this.router.navigateByUrl('/unauthorized-user');
      this.load = false;
    }
  }

  async showInfo(info) {
    this.loading = true;
    if (this.keycloakService === undefined || this.keycloakService === null
      || this.keycloakService.getKeycloakInstance() === undefined
      || this.keycloakService.getKeycloakInstance() === null) {
      if (info === 'browser') {
        this.load = false;
        // this.confirm('Browser doesn\'t fulfill the browser requirements:' +
        // ' The Browser requirements are: Browser type = Chrome, Minimum browser version = 80.0.3987.122');
        localStorage.setItem('message', 'Browser doesn\'t fulfill the browser requirements:' +
        ' The Browser requirements are: Browser type = Chrome, Minimum browser version = 80.0.3987.122');
        localStorage.setItem('info', this.infoText);
        localStorage.setItem('but', this.closeText);
        this.router.navigateByUrl('xxxxx');
      } else if (info === 'user') {
        // this.confirm('USER NOT FOUND IN USM. User can\'t continue using the application !!!!!');
        localStorage.setItem('message', 'USER NOT FOUND IN USM. User can\'t continue using the application !!!!!');
        localStorage.setItem('info', this.infoText);
        localStorage.setItem('but', this.closeText);
        this.router.navigateByUrl('xxxxx');
      }
      this.loading = false;
      return;
    } else {
      if (localStorage.getItem('language') === null) {
        // Replace the user id with the users user id later
        const lang = await this.language.getLanguage(1).toPromise().catch(err => this.handleError(err));
        if (lang.userId > 0) {
          localStorage.setItem('language', lang.lang);

          this.translate.addLangs(['English', 'French']);
          this.translate.setDefaultLang('English');

          let browserLang = this.translate.getBrowserLang();
          if (localStorage.getItem('language') !== null) {
            browserLang = localStorage.getItem('language');
          }
          this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
        } else {
          this.translate.addLangs(['English', 'French']);
          this.translate.setDefaultLang('English');

          let browserLang = this.translate.getBrowserLang();
          if (localStorage.getItem('language') !== null) {
            browserLang = localStorage.getItem('language');
          }
          this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
        }

    // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.INFO').subscribe((resp) => {
          this.infoText = resp;
        });

    // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.CLOSE').subscribe((resp) => {
          this.closeText = resp;
        });

        if (info === 'browser') {
          this.load = false;
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.BROWSERCHECK').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        } else if (info === 'user') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.USERNOTFOUND').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        }
      } else {
        if (info === 'browser') {
          this.load = false;
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.BROWSERCHECK').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        } else if (info === 'user') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.USERNOTFOUND').subscribe((resp) => {
            // this.confirm(resp);
            localStorage.setItem('message', resp);
            localStorage.setItem('info', this.infoText);
            localStorage.setItem('but', this.closeText);
            this.router.navigateByUrl('xxxxx');
          });
        }
      }
    }

    this.loading = false;
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
    console.log(err);
  }
}
