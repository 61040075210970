import { Setup } from './../../models/setup';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Category } from 'src/app/models/category';
import { Int64Updater } from 'src/app/models/key-value';
import { Urls } from 'src/app/models/urls';

@Injectable({
  providedIn: 'root'
})
export class SetupService {

  url = new Urls();
  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
  }

  getSetups(page, size, catId, setupId) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/setup/' + page + '/' + size + '/' + catId + '/' + setupId;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postSetup(set: Setup) {
    return this.httpclient.post(this.apiUrl + '/api/setup', set)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putSetup(val: Int64Updater) {
    return this.httpclient.put(this.apiUrl + '/api/setup/' + val.Id, val)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteSetup(id: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete<any>(this.apiUrl + '/api/setup/' + id, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
