export class Category {
    // tslint:disable-next-line: variable-name
    public org_id: number;
    // tslint:disable-next-line: variable-name
    public cat_id: number;
    // tslint:disable-next-line: variable-name
    public cat_name: string;
    // tslint:disable-next-line: variable-name
    public cat_desc: string;
}
