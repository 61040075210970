import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Urls } from 'src/app/models/urls';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  url = new Urls();
  baseUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.baseUrl = environment.portalApiUrl;
  }

  getLanguage(uid: number) {
    return this.httpclient.get<any>(this.baseUrl + '/api/language/' + uid)
                          .pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    console.error('error', error);
    return error;
  }

}
