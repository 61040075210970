import { SessionValue } from './../../models/session';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Urls } from 'src/app/models/urls';

@Injectable({
  providedIn: 'root'
})
export class SessionValueService {

  url = new Urls();
  dcmApiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.dcmApiUrl = this.url.dcmApiUrl;
  }

  getSessionValues(sid) {
    // tslint:disable-next-line: max-line-length
    const url = this.dcmApiUrl + '/api/sessionvalue/' + sid + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postSessionValue(sv: SessionValue) {
    return this.httpclient.post<any>(this.dcmApiUrl + '/api/sessionvalue', sv)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteSessionValue(sess: SessionValue): Observable<any> {
    const val = JSON.stringify(sess);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete<any>(this.dcmApiUrl + '/api/sessionvalue/' + val, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
