
export class KeyValue {
    public key: string;
    public Value: string;
}

export class Int64Updater {
    public Id: number;
    public Values: KeyValue[];
}

export class StringUpdater {
    public Id: string;
    public Values: KeyValue[];
}
