export class Setup {
    // tslint:disable-next-line: variable-name
    public cat_id: number;
    // tslint:disable-next-line: variable-name
    public setup_id: number;
    // tslint:disable-next-line: variable-name
    public setup_name: string;
    // tslint:disable-next-line: variable-name
    public setup_desc: string;
    // tslint:disable-next-line: variable-name
    public rcp_name: string;
}
