import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CategoryService } from 'src/app/services/category/category.service';
import { GroupService } from 'src/app/services/group/group.service';
import { RecipeService } from 'src/app/services/recipe/recipe.service';
import { SetupPermsService } from 'src/app/services/setup-permission/setup-perms.service';
import { SetupService } from 'src/app/services/setup/setup.service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/models/variables';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  loading = false;
  loadmore = false;
  categories = [];
  page = 1;
  size = 100;

  cate = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private catService: CategoryService,
    private setService: SetupService,
    private permService: SetupPermsService,
    private messageService: MessageService,
    private recipeService: RecipeService,
    private groupService: GroupService
    ) {
     this.translate.addLangs(['English', 'French']);
     this.translate.setDefaultLang('English');

     let browserLang = this.translate.getBrowserLang();

     const pass = 'ranitessarldev2019';
     const lang = cookieService.get('goepla-portal-language');
     if (lang.trim() !== '') {
       const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
       const lg = declang;
       browserLang = lg;
       localStorage.setItem('language', lg);
     }

     if (localStorage.getItem('language') !== null) {
       browserLang = localStorage.getItem('language');
     }
     this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
   }

   ngOnInit() {
    this.getCategories();
  }

  async getCategories() {
    this.loading = true;
    const v = new Variables();
    const va = localStorage.getItem('mg');
    const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const groups = mg;
    // const groups = localStorage.getItem('my-groups');

    const cats = await this.catService.getCategories(this.page, this.size, 0, groups).toPromise()
                                      .catch(err => this.handleError(err));
    if (cats != null && cats.length > 0) {
      if (cats.length >= this.size) {
        this.loadmore = true;
      } else {
        this.loadmore = false;
      }

      for (const cat of cats) {
        this.categories.push(cat);
      }
    } else {
      this.loadmore = false;
      this.translate.get('GENERAL.INFO').subscribe((resp: string) => {
        this.translate.get('GENERAL.NOCATEGORIES').subscribe((resp2: string) => {
          this.showInfo(resp, resp2);
        });
      });
    }

    this.loading = false;
  }

  selectCat(cat) {
    localStorage.setItem('category', JSON.stringify(cat));
    localStorage.removeItem('setup');
    this.translate.get('GENERAL.CATEGORYSELECTED').subscribe((resp: string) => {
      this.showInfo('', resp);
    });
    this.gotoSetup();
  }

  loadMore() {
    this.page += 1;
    this.getCategories();
  }

  gotoSetup() {
    this.router.navigateByUrl('/select-setup');
  }

  gotoDataCollection() {
    this.router.navigateByUrl('/data-collection');
  }

  showSuccess(title, message) {
    this.messageService.add({ key: 'tc', severity: 'success', summary: title, detail: message });
  }

  showInfo(title, message) {
      this.messageService.add({ key: 'tc', severity: 'info', summary: title, detail: message });
  }

  showWarn(title, message) {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: title, detail: message });
  }

  showError(title, message) {
      this.messageService.add({ key: 'tc', severity: 'error', summary: title, detail: message });
  }

  handleError(err) {
    console.log(err);
    this.loading = false;
  }

}
