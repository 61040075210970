import { SessionFileService } from './../../services/session-file/session-file.service';
import { SessionFile } from './../../models/session-file';
import { Params } from './../../models/params';
import { FileHolder } from './../../models/file-holder';
import { FileService } from './../../services/file/file.service';
import { Random } from './../../helpers/random';
import { RecipeParameterService } from './../../services/recipe-parameter/recipe-parameter.service';
import { SessionService } from './../../services/session/session.service';
import { SessoinTextService } from './../../services/session-text/sessoin-text.service';
import { SessionValueService } from './../../services/session-value/session-value.service';
import { Session, SessionText, SessionValue } from './../../models/session';
import { StepService } from './../../services/step/step.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CategoryService } from 'src/app/services/category/category.service';
import { GroupService } from 'src/app/services/group/group.service';
import { RecipeService } from 'src/app/services/recipe/recipe.service';
import { SetupPermsService } from 'src/app/services/setup-permission/setup-perms.service';
import { SetupService } from 'src/app/services/setup/setup.service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/models/variables';

@Component({
  selector: 'app-data-collection',
  templateUrl: './data-collection.component.html',
  styleUrls: ['./data-collection.component.scss']
})
export class DataCollectionComponent implements OnInit {

  exitText = 'Exit';

  set: any;
  cat: any;

  recipe: any;

  steps = [];
  stepparams = [];
  sessionparams = [];

  loading = false;
  isvisible = false;

  ext1: string;
  ext2: string;
  ext3: string;
  ext4: string;
  ext5: string;

  nameext1: string;
  nameext2: string;
  nameext3: string;
  nameext4: string;
  nameext5: string;

  sid: string;

  response = true;

  files = [];
  fileIds = [];

  sesse = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private catService: CategoryService,
    private setService: SetupService,
    private permService: SetupPermsService,
    private messageService: MessageService,
    private recipeService: RecipeService,
    private groupService: GroupService,
    private stepService: StepService,
    private svService: SessionValueService,
    private stService: SessoinTextService,
    private sessionService: SessionService,
    private paramService: RecipeParameterService,
    private fileService: FileService,
    private sfService: SessionFileService
    ) {
     this.translate.addLangs(['English', 'French']);
     this.translate.setDefaultLang('English');

     let browserLang = this.translate.getBrowserLang();

     const pass = 'ranitessarldev2019';
     const lang = cookieService.get('goepla-portal-language');
     if (lang.trim() !== '') {
       const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
       const lg = declang;
       browserLang = lg;
       localStorage.setItem('language', lg);
     }

     if (localStorage.getItem('language') !== null) {
       browserLang = localStorage.getItem('language');
     }
     this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

     this.translate.get('GENERAL.EXIT').subscribe((resp: string) => {
      this.exitText = resp;
     });
   }

  async ngOnInit() {
    const set = JSON.parse(localStorage.getItem('setup'));
    if (set !== undefined && set !== null) {
      this.set = set;
      this.loading = true;
      await this.getCategory();
      await this.getRecipe();
      await this.getRecipeParameters();
      await this.getSteps();

      this.isvisible = true;
      this.loading = false;
    }
  }

  async getCategory() {
    const cat = await this.catService.getCategories(1, 1, this.set.cat_id, ' ').toPromise()
                                      .catch(err => this.handleError(err));
    if (cat != null && cat.length > 0) {
      for (const val of cat) {
        this.cat = val;
      }
    }
  }

  async getRecipe() {
    const rcp = await this.recipeService.getRecipes(1, 1, 0, this.set.rcp_name).toPromise()
                                          .catch(err => this.handleError(err));
    if (rcp != null && rcp.length > 0) {
      for (const val of rcp) {
        this.recipe = val;
        this.nameext1 = this.recipe.name_sess_ext_id1;
        this.nameext2 = this.recipe.name_sess_ext_id2;
        this.nameext3 = this.recipe.name_sess_ext_id3;
        this.nameext4 = this.recipe.name_sess_ext_id4;
        this.nameext5 = this.recipe.name_sess_ext_id5;
      }
    }
  }

  async getSteps() {
    let run = true;
    let page = 0;
    while (run === true) {
      page += 1;
      const stps = await this.stepService.getSteps(page, 100, this.recipe.rcp_id, 0).toPromise()
                                        .catch(err => this.handleError(err));
      if (stps != null && stps.length > 0) {
        for (const step of stps) {
          if (step.step_mandatory === 'Y') {
            step.man = true;
          } else {
            step.man = false;
          }
          step.value = null;
          step.params = [];
          for (const param of this.stepparams) {
            if (param.StepNr === step.step_nr) {
              step.params.push(param);
            }
          }
          this.steps.push(step);
        }
      } else {
        run = false;
      }
    }
  }

  async getRecipeParameters() {
    const params = await this.paramService.getRecipeParameters(0, 0, this.recipe.rcp_id).toPromise()
                                          .catch(err => this.handleError(err));

    if (params != null && params.length > 0) {
      for (const param of params) {
        const split = param.par_id.split(':', 3);

        const par = new Params();
        par.Id = param.par_id;
        par.Type = split[0];
        par.StepNr = +split[1];
        par.Name = split[2];
        par.Desc = param.par_desc;
        par.Value = param.par_val;
        if (param.par_val === 0) {
          par.Mandatory = false;
        } else {
          par.Mandatory = true;
        }

        if (split[1] === '0') {
          this.sessionparams.push(par);
        } else {
          this.stepparams.push(par);
        }
      }

      this.stepparams = this.stepparams.sort((a, b) => a.Type.localeCompare(b.Type)).reverse();
      this.sessionparams = this.sessionparams.sort((a, b) => a.Type.localeCompare(b.Type)).reverse();
    }
  }

  async saveSession() {
    this.loading = true;

    // check if session id 1 has been provided
    if (this.ext1 === undefined || this.ext1 == null || this.ext1.trim() === '') {
      this.translate.get('GENERAL.ENTER').subscribe((resp: string) => {
        this.confirm(resp + ' ' + this.nameext1);
      });
      this.loading = false;
    } /* check if session id 2 is present and has been provided */ else if (this.nameext2 !== undefined
      && this.nameext2 != null && this.nameext2.trim() !== '' && (this.ext2 === undefined
     || this.ext2 == null || this.ext2.trim() === '')) {
      this.translate.get('GENERAL.ENTER').subscribe((resp: string) => {
        this.confirm(resp + ' ' + this.nameext2);
      });
      this.loading = false;
    } /* check if session id 3 is present and has been provided */ else if (this.nameext3 !== undefined
      && this.nameext3 != null && this.nameext3.trim() !== '' && (this.ext3 === undefined
     || this.ext3 == null || this.ext3.trim() === '')) {
      this.translate.get('GENERAL.ENTER').subscribe((resp: string) => {
        this.confirm(resp + ' ' + this.nameext3);
      });
      this.loading = false;
    } /* check if session id 4 is present and has been provided */ else if (this.nameext4 !== undefined
      && this.nameext4 != null && this.nameext4.trim() !== '' && (this.ext4 === undefined
     || this.ext4 == null || this.ext4.trim() === '')) {
      this.translate.get('GENERAL.ENTER').subscribe((resp: string) => {
        this.confirm(resp + ' ' + this.nameext4);
      });
      this.loading = false;
    } /* check if session id 5 is present and has been provided */ else if (this.nameext5 !== undefined
      && this.nameext5 != null && this.nameext5.trim() !== '' && (this.ext5 === undefined
     || this.ext5 == null || this.ext5.trim() === '')) {
      this.translate.get('GENERAL.ENTER').subscribe((resp: string) => {
        this.confirm(resp + ' ' + this.nameext5);
      });
      this.loading = false;
    } else {
      // check for missing step values
      for (const val of this.steps) {
        // check if mandatory step values have been provided
        if (val.step_mandatory === 'Y' && (val.value === undefined || val.value == null || val.value.toString().trim() === '')) {
          this.translate.get('GENERAL.PROVIDEALLMANDATORYSTEPVALUES').subscribe((resp: string) => {
            this.confirm(resp);
          });
          this.loading = false;
          return;
        }

        // for recipe parameters
        for (const param of val.params) {
          // check if mandatory paramters have been provided
          if (param.Mandatory === true) {
            // for text types
            if (param.Type === 'TEXT') {
              // check if parameter value has been provided
              if (param.NewValue === undefined || param.NewValue == null || param.NewValue.trim() === '') {
                this.translate.get('GENERAL.PROVIDEALLMANDATORYSTEPPARAMETERVALUES').subscribe((resp: string) => {
                  this.confirm(resp);
                });
                this.loading = false;
                return;
              }
            } /* for attachements */ else if (param.Type === 'ATTACHMENT') {
              // check if file name has been provided
              if (param.FileName === undefined || param.FileName == null || param.FileName.trim() === '') {
                this.translate.get('GENERAL.PROVIDEALLMANDATORYSTEPPARAMETERFILEATTACHMENTFILENAMES').subscribe((resp: string) => {
                  this.confirm(resp);
                });
                this.loading = false;
                return;
              } /* check if file comment has been povided */ else if (param.FileComment === undefined
               || param.FileComment == null || param.FileComment.trim() === '') {
                this.translate.get('GENERAL.PROVIDEALLMANDATORYSTEPPARAMETERFILEATTACHMENTFILECOMMENTS').subscribe((resp: string) => {
                  this.confirm(resp);
                });
                this.loading = false;
                return;
              } /* check if file has been selected */ else {
                const file = this.files.find(f => f.Id === param.Id);
                if (file === undefined || file == null) {
                  this.translate.get('GENERAL.SELECTALLMANDATORYSTEPPARAMETERFILEATTACHMENTS').subscribe((resp: string) => {
                    this.confirm(resp);
                  });
                  this.loading = false;
                  return;
                }
              }
            }
          }
        }
      }

      // check for missing session values
      for (const param of this.sessionparams) {
        // check if mandatory paramters have been provided
        if (param.Mandatory === true) {
          // for text types
          if (param.Type === 'TEXT') {
            // check if parameter value has been provided
            if (param.NewValue === undefined || param.NewValue == null || param.NewValue.trim() === '') {
              this.translate.get('GENERAL.PROVIDEALLMANDATORYSESSIONVALUES').subscribe((resp: string) => {
                this.confirm(resp);
              });
              this.loading = false;
              return;
            }
          } /* for attachements */ else if (param.Type === 'ATTACHMENT') {
            // check if file name has been provided
            if (param.FileName === undefined || param.FileName == null || param.FileName.trim() === '') {
              this.translate.get('GENERAL.PROVIDEALLMANDATORYSESSIONFILEATTACHMENTFILENAMES').subscribe((resp: string) => {
                this.confirm(resp);
              });
              this.loading = false;
              return;
            } /* check if file comment has been povided */ else if (param.FileComment === undefined
            || param.FileComment == null || param.FileComment.trim() === '') {
              this.translate.get('GENERAL.PROVIDEALLMANDATORYSESSIONFILEATTACHMENTFILECOMMENTS').subscribe((resp: string) => {
                this.confirm(resp);
              });
              this.loading = false;
              return;
            } /* check if file has been selected */ else {
              const file = this.files.find(f => f.Id === param.Id);
              if (file === undefined || file == null) {
                this.translate.get('GENERAL.SELECTALLMANDATORYSESSIONFILEATTACHMENTS').subscribe((resp: string) => {
                  this.confirm(resp);
                });
                this.loading = false;
                return;
              }
            }
          }
        }
      }

      const sess = new Session();
      const v = new Variables();
      const va = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8).toString();

      sess.org_id = +oi;
      sess.rcp_id = this.recipe.rcp_id;
      sess.oprator = this.recipe.oprator;
      sess.sess_ext_id1 = this.ext1;
      sess.sess_ext_id2 = this.ext2;
      sess.sess_ext_id3 = this.ext3;
      sess.sess_ext_id4 = this.ext4;
      sess.sess_ext_id5 = this.ext5;

      // save session
      this.sid = await this.sessionService.postSession(sess).toPromise().catch(err => this.handleError(err));

      // check if session is saved and the session id returned
      if (this.sid !== undefined && this.sid != null && this.sid.toString().trim() !== '') {
        // save parameters and attachments for steps
        for (const val of this.stepparams) {
          if (val.Type === 'TEXT') {
            if (val.NewValue !== undefined && val.NewValue != null && val.NewValue.trim() !== '') {
              const st = new SessionText();
              st.sess_id = this.sid;
              st.step_nr = val.StepNr;
              st.txt_id = val.Name;
              st.txt_val = val.NewValue;

              const res = await this.stService.postSessionText(st).toPromise().catch(err => this.handleError(err));
              // check if step parameter is saved
              if (res === undefined || res === null || res.toString().trim() === '') {
                this.translate.get('GENERAL.FAILED').subscribe((resp: string) => {
                  this.translate.get('GENERAL.STEPPARAMETERNOTSAVED').subscribe((resp2: string) => {
                    this.showError(resp, resp2);
                  });
                });
                this.loading = false;
                return;
              }
            }
          } else if (val.Type === 'ATTACHMENT') {
            if (val.FileName !== undefined && val.FileName != null && val.FileName.trim() !== ''
                && val.FileComment !== undefined && val.FileComment != null && val.FileComment.trim() !== '') {
              const file = this.files.find(f => f.Id === val.Id);
              if (file !== undefined && file != null) {
                // upload file
                const fid = await this.uploadFile(file.File, val.FileName);

                // check file is uploaded
                if (fid === undefined || fid === null || fid.toString().trim() === '') {
                  this.translate.get('GENERAL.FAILED').subscribe((resp: string) => {
                    this.translate.get('GENERAL.FILENOTUPLOADED').subscribe((resp2: string) => {
                      this.showError(resp, resp2);
                    });
                  });
                  this.loading = false;
                  return;
                }
                if (fid !== undefined && fid != null && fid.toString().trim() !== '') {
                  const sf = new SessionFile();
                  sf.sess_id = this.sid;
                  sf.step_nr = val.StepNr;
                  sf.sess_file_id = val.Name;
                  sf.sess_filename = val.FileName;
                  sf.file_cmt = val.FileComment;
                  sf.flm_file_id = fid.toString();

                  const res = await this.sfService.postSessionFile(sf).toPromise().catch(err => this.handleError(err));

                  // check if step file is saved
                  if (res === undefined || res === null || res.toString().trim() === '') {
                    this.translate.get('GENERAL.FAILED').subscribe((resp: string) => {
                      this.translate.get('GENERAL.STEPFILENOTSAVED').subscribe((resp2: string) => {
                        this.showError(resp, resp2);
                      });
                    });
                    this.loading = false;
                    return;
                  }
                }
              }
            }
          }
        }

        // save parameters and attachments for session
        for (const val of this.sessionparams) {
          if (val.Type === 'TEXT') {
            if (val.NewValue !== undefined && val.NewValue != null && val.NewValue.trim() !== '') {
              const st = new SessionText();
              st.sess_id = this.sid;
              st.step_nr = 0;
              st.txt_id = val.Name;
              st.txt_val = val.NewValue;

              const res = await this.stService.postSessionText(st).toPromise().catch(err => this.handleError(err));

              // check if session parameter is saved
              if (res === undefined || res === null || res.toString().trim() === '') {
                this.translate.get('GENERAL.FAILED').subscribe((resp: string) => {
                  this.translate.get('GENERAL.SESSIONPARAMETERNOTSAVED').subscribe((resp2: string) => {
                    this.showError(resp, resp2);
                  });
                });
                this.loading = false;
                return;
              }
            }
          } else if (val.Type === 'ATTACHMENT') {
            if (val.FileName !== undefined && val.FileName != null && val.FileName.trim() !== ''
                && val.FileComment !== undefined && val.FileComment != null && val.FileComment.trim() !== '') {
              const file = this.files.find(f => f.Id === val.Id);
              if (file !== undefined && file != null) {
                // upload file
                const fid = await this.uploadFile(file.File, val.FileName);

                // check if file is uploaded
                if (fid === undefined || fid === null || fid.toString().trim() === '') {
                  this.translate.get('GENERAL.FAILED').subscribe((resp: string) => {
                    this.translate.get('GENERAL.FILENOTUPLOADED').subscribe((resp2: string) => {
                      this.showError(resp, resp2);
                    });
                  });
                  this.loading = false;
                  return;
                }
                if (fid !== undefined && fid != null && fid.toString().trim() !== '') {
                  const sf = new SessionFile();
                  sf.sess_id = this.sid;
                  sf.step_nr = 0;
                  sf.sess_file_id = val.Name;
                  sf.sess_filename = val.FileName;
                  sf.file_cmt = val.FileComment;
                  sf.flm_file_id = fid.toString();

                  const res = await this.sfService.postSessionFile(sf).toPromise().catch(err => this.handleError(err));

                  // check if session file is saved
                  if (res === undefined || res === null || res.toString().trim() === '') {
                    this.translate.get('GENERAL.FAILED').subscribe((resp: string) => {
                      this.translate.get('GENERAL.SESSIONFILENOTSAVED').subscribe((resp2: string) => {
                        this.showError(resp, resp2);
                      });
                    });
                    this.loading = false;
                    return;
                  }
                }
              }
            }
          }
        }

        // save session step values
        for (const val of this.steps) {
          if (val.value !== undefined && val.value != null && val.value.toString().trim() !== '') {
            const sv = new SessionValue();
            sv.step_nr = val.step_nr;
            sv.val = val.value;
            sv.sess_id = this.sid;
            if (val.value >= val.ll && val.value <= val.ul) {
              sv.res = 'P';
            } else {
              sv.res = 'F';
              this.response = false;
            }

            const res = await this.svService.postSessionValue(sv).toPromise().catch(err => this.handleError(err));

            // check if step value is saved
            if (res === undefined || res === null || res !== true) {
              this.translate.get('GENERAL.FAILED').subscribe((resp: string) => {
                this.translate.get('GENERAL.STEPVALUENOTSAVED').subscribe((resp2: string) => {
                  this.showError(resp, resp2);
                });
              });
              this.loading = false;
              return;
            }
          }
        }

        // add result to session
        if (this.response === true) {
          // add session status passed
          sess.res = 'P';
        } else {
          // add session status failed
          sess.res = 'F';
        }

        // add session id to session
        sess.sess_id = this.sid;

        // update session
        const updateres = await this.sessionService.putSession(this.sid, sess).toPromise()
                                                    .catch(err => this.handleError(err));

        // check if session updated
        if (updateres === true) {
          // session updated

          // get final session info
          const sessioninfo = await this.sessionService.getSessions(1, 1, this.recipe.rcp_id, this.sid).toPromise()
                                                        .catch(err => this.handleError(err));

          // check if session info is returned
          if (sessioninfo != null && sessioninfo.length > 0) {
            if (sessioninfo[0].res === 'P') {
              // session passed
              this.translate.get('GENERAL.SESSIONSAVED').subscribe((resp: string) => {
                this.translate.get('GENERAL.SESSIONPASSED').subscribe((resp2: string) => {
                  this.showSuccessDuration(resp, resp2);
                });
              });
            } else if (sessioninfo[0].res === 'F') {
              // session failed
              this.translate.get('GENERAL.SESSIONSAVED').subscribe((resp: string) => {
                this.translate.get('GENERAL.SESSIONFAILED').subscribe((resp2: string) => {
                  this.showWarnDuration(resp, resp2);
                });
              });
            }

            // clear values
            this.isvisible = false;
            this.steps = [];
            this.stepparams = [];
            this.sessionparams = [];
            this.sid = null;
            this.nameext1 = '';
            this.nameext2 = '';
            this.nameext3 = '';
            this.nameext4 = '';
            this.nameext5 = '';
            this.ext1 = null;
            this.ext2 = null;
            this.ext3 = null;
            this.ext4 = null;
            this.ext5 = null;
            await this.getCategory();
            await this.getRecipe();
            await this.getRecipeParameters();
            await this.getSteps();
            this.isvisible = true;
          } else {
            // could not get session info
            this.translate.get('GENERAL.FAILED').subscribe((resp: string) => {
              this.translate.get('GENERAL.COULDNOTGETSESSIONINFO').subscribe((resp2: string) => {
                this.showError(resp, resp2);
              });
            });
          }
        } else {
          // session not updated
          this.translate.get('GENERAL.FAILED').subscribe((resp: string) => {
            this.translate.get('GENERAL.COULDNOTUPDATESESSION').subscribe((resp2: string) => {
              this.showError(resp, resp2);
            });
          });
        }
      } else {
        this.translate.get('GENERAL.FAILED').subscribe((resp: string) => {
          this.translate.get('GENERAL.SESSIONNOTCREATED').subscribe((resp2: string) => {
            this.showError(resp, resp2);
          });
        });
      }

      this.loading = false;
    }
  }

  gotoSetup() {
    this.router.navigateByUrl('/select-setup');
  }

  gotoCategory() {
    this.router.navigateByUrl('/select-category');
  }

  exit() {
    this.set = null;
    this.cat = null;
    this.recipe = null;
    this.steps = [];
    localStorage.removeItem('setup');
    localStorage.removeItem('category');
    this.router.navigateByUrl('/select-category');
  }

  async uploadFile(file, filename) {
    const formdata = new FormData();
    formdata.append('file', file, 'dcm-bucket' + ':' + filename);

    const fid = await this.fileService.postFile(formdata).toPromise().catch(err => this.handleError(err));

    return fid;
  }

  onFileSelected(name, value, stepNr) {
    let inputNode: any;
    if (name === 'step') {
      // inputNode = document.querySelector('#file' + value);
      inputNode = document.getElementById('file' + stepNr + value);
    } else {
      // inputNode = document.querySelector('#file');
      inputNode = document.getElementById('file' + value);
    }

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (inputNode.files[0]) {
        reader.readAsDataURL(inputNode.files[0]);
      }

      reader.onloadend = (e: any) => {
        const filename = inputNode.files[0].name;
        const base64 = e.target.result;
        const blob = this.dataURItoBlob(base64);

        const fholder = new FileHolder();
        fholder.Id = value;
        fholder.File = blob;
        if (name === 'session') {
          fholder.StepNumber = 0;
          for (const val of this.sessionparams) {
            if (val.Id === value) {
              val.IsVisible = true;
              val.FileName = filename;
              val.FileComment = filename;
            }
          }
        } else {
          fholder.StepNumber = +stepNr;
          for (const val of this.stepparams) {
            if (val.Id === value) {
              val.IsVisible = true;
              val.FileName = filename;
              val.FileComment = filename;
            }
          }
        }

        const file = this.files.find(f => f.Id === fholder.Id);
        if (file !== undefined && file != null) {
          const index = this.files.indexOf(file, 0);
          if (index > -1) {
            this.files.splice(index, 1);
         }
        }

        this.files.push(fholder);
      };
      reader.onerror = ((err) => {
        console.log(err);
      });
    } else {
      console.log('file type undefiled');
    }
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
  }

  showSuccess(title, message) {
    this.messageService.add({ key: 'tc', severity: 'success', summary: title, detail: message });
  }

  showInfo(title, message) {
      this.messageService.add({ key: 'tc', severity: 'info', summary: title, detail: message });
  }

  showWarn(title, message) {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: title, detail: message });
  }

  showError(title, message) {
      this.messageService.add({ key: 'tc', severity: 'error', summary: title, detail: message });
  }

  showSuccessDuration(title, message) {
    this.messageService.add({ key: 'tc', severity: 'success', summary: title, detail: message, life: 60000 });
  }

  showWarnDuration(title, message) {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: title, detail: message, life: 60000 });
  }

  confirm(text) {
    this.confirmationService.confirm({
        message: text,
        accept: async () => {
            // Accept logic
        },
        reject: () => {
          // Reject logic
        }
    });
  }

  handleError(err) {
    this.loading = false;
    if (err != null && err.error != null && err.error.Message === 'Session creation not allowed. Recipe not released') {
      this.translate.get('GENERAL.SESSIONNOTCREATEDRECIPENOTRELEASED').subscribe((resp: string) => {
        this.confirm(resp);
      });
    } else if (err != null && err.error != null
               && err.error.MessageText === 'duplicate key value violates unique constraint "t_file_ext_name_key"') {
      this.translate.get('GENERAL.FILEALREADYEXISTS').subscribe((resp: string) => {
        this.confirm(resp);
      });
    } else {
      console.log(err);
    }
  }

}
