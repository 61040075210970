import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { SessionText } from 'src/app/models/session';
import { SessionFile } from 'src/app/models/session-file';
import { Urls } from 'src/app/models/urls';

@Injectable({
  providedIn: 'root'
})
export class SessionFileService {

  url = new Urls();
  dcmApiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.dcmApiUrl = this.url.dcmApiUrl;
  }

  getSessionFiles(sid, fid) {
    // tslint:disable-next-line: max-line-length
    const url = this.dcmApiUrl + '/api/sessionfile/' + sid + '/' + fid + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postSessionFile(sf: SessionFile) {
    return this.httpclient.post<any>(this.dcmApiUrl + '/api/sessionfile', sf)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putSessionFile(id: string, sf: SessionFile): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.put<any>(this.dcmApiUrl + '/api/sessionfile/' + id, sf, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteSessionFile(sf: SessionFile): Observable<any> {
    const val = JSON.stringify(sf);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete<any>(this.dcmApiUrl + '/api/sessionfile/' + val, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
