import { BrowserComponent } from './components/browser/browser.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { DataCollectionComponent } from './components/data-collection/data-collection.component';
import { CategoryComponent } from './components/category/category.component';
import { SetupComponent } from './components/setup/setup.component';
import { AdministrationComponent } from './components/administration/administration.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, OnInit } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AccordionModule } from 'primeng/accordion';     // accordion and accordion tab
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';                  // api
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';

import { ToolbarModule } from 'primeng/toolbar';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { MenubarModule } from 'primeng/menubar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TabViewModule } from 'primeng/tabview';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PickListModule } from 'primeng/picklist';
import { CalendarModule } from 'primeng/calendar';
import { ColorPickerModule } from 'primeng/colorpicker';
import { MultiSelectModule } from 'primeng/multiselect';
import { CardModule } from 'primeng/card';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';
import { InputNumberModule } from 'primeng/inputnumber';
import { ProgressBarModule } from 'primeng/progressbar';
import { TooltipModule } from 'primeng/tooltip';

import { FormsModule } from '@angular/forms';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

import { detect } from 'detect-browser';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { HttpConfigInterceptor } from './Interceptors/httpconfig.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';

const keycloakService = new KeycloakService();
const browser = detect();

@NgModule({
  declarations: [
    AppComponent,
    AdministrationComponent,
    SetupComponent,
    CategoryComponent,
    DataCollectionComponent,
    UnauthorizedComponent,
    BrowserComponent
   ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,

    AccordionModule,
    PanelModule,
    ButtonModule,
    RadioButtonModule,
    ToolbarModule,
    SplitButtonModule,
    TableModule,
    DropdownModule,
    InputTextModule,
    MessagesModule,
    MessageModule,
    ConfirmDialogModule,
    MenubarModule,
    ProgressSpinnerModule,
    PickListModule,
    CalendarModule,
    MultiSelectModule,
    DialogModule,
    CardModule,
    ScrollPanelModule,
    ToastModule,
    InputNumberModule,
    ProgressBarModule,
    TooltipModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot([
      // { path: '', redirectTo: 'select-category', pathMatch: 'full' },
      { path: 'select-category', component: CategoryComponent },
      { path: 'select-setup', component: SetupComponent },
      { path: 'data-collection', component: DataCollectionComponent },
      { path: 'administration', component: AdministrationComponent },
      { path: 'unauthorized-user', component: UnauthorizedComponent },
      { path: 'xxxxx', component: BrowserComponent }
    ])
  ],
  providers: [
    CookieService,
    MessageService,
    ConfirmationService,
    MessageService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: KeycloakService,
      useValue: keycloakService
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  //  bootstrap: [
  //     AppComponent
  //  ]
  entryComponents: [
    AppComponent
  ]
})
export class AppModule {
  constructor(
    private router: Router
  ) {
    localStorage.setItem('oi', '');
    localStorage.setItem('ui', '');
  }

  ngDoBootstrap(app) {
    localStorage.setItem('browser', 'true');
    keycloakService
      .init(
        {
          config: {
            url: environment.keycloakUrl + '/auth',
            realm: environment.realm,
            clientId: environment.clientid
          },
          initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: false
          },
          enableBearerInterceptor: true,
          bearerPrefix: 'Bearer',
          bearerExcludedUrls: [
              '/assets',
              '/clients/public']
        })
      .then(async () => {
        // console.log('[ngDoBootstrap] bootstrap app');

        // console.log((await keycloakService.loadUserProfile()).email)

        localStorage.setItem('oi', '');
        localStorage.setItem('ui', '');
        app.bootstrap(AppComponent);

        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production });
      })
      .catch(
        error => console.error('[ngDoBootstrap] init Keycloak failed', error)
      );
  }
}
