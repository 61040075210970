import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CategoryService } from 'src/app/services/category/category.service';
import { GroupService } from 'src/app/services/group/group.service';
import { RecipeService } from 'src/app/services/recipe/recipe.service';
import { SetupPermsService } from 'src/app/services/setup-permission/setup-perms.service';
import { SetupService } from 'src/app/services/setup/setup.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {

  setups = [];

  loading = false;

  page = 1;
  size = 100;

  loadmore = false;

  sete = false;
  sesse = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private catService: CategoryService,
    private setService: SetupService,
    private permService: SetupPermsService,
    private messageService: MessageService,
    private recipeService: RecipeService,
    private groupService: GroupService
    ) {
     this.translate.addLangs(['English', 'French']);
     this.translate.setDefaultLang('English');

     let browserLang = this.translate.getBrowserLang();

     const pass = 'ranitessarldev2019';
     const lang = cookieService.get('goepla-portal-language');
     if (lang.trim() !== '') {
       const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
       const lg = declang;
       browserLang = lg;
       localStorage.setItem('language', lg);
     }

     if (localStorage.getItem('language') !== null) {
       browserLang = localStorage.getItem('language');
     }
     this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
   }

  ngOnInit() {
    const cat = JSON.parse(localStorage.getItem('category'));
    if (cat !== undefined && cat !== null) {
      this.getSetups(cat);
    }
  }

  async getSetups(cat) {
    this.loading = true;
    const sets = await this.setService.getSetups(this.page, this.size, cat.cat_id, 0).toPromise()
                                      .catch(err => this.handleError(err));
    if (sets != null && sets.length > 0) {
      if (sets.length >= this.size) {
        this.loadmore = true;
      } else {
        this.loadmore = false;
      }

      for (const set of sets) {
        this.setups.push(set);
      }
    } else {
      this.loadmore = false;
      this.translate.get('GENERAL.INFO').subscribe((resp: string) => {
        this.translate.get('GENERAL.NOSETUPS').subscribe((resp2: string) => {
          this.showInfo(resp, resp2);
        });
      });
    }
    this.loading = false;
  }

  selectSet(set) {
    localStorage.setItem('setup', JSON.stringify(set));
    this.translate.get('GENERAL.SETUPSELECTED').subscribe((resp: string) => {
      this.showInfo('', resp);
    });
    this.gotoDataCollection();
  }

  loadMore() {
    this.page += 1;
    const cat = JSON.parse(localStorage.getItem('category'));
    if (cat !== undefined && cat !== null) {
      this.getSetups(cat);
    }
  }

  gotoCategory() {
    this.router.navigateByUrl('/select-category');
  }

  gotoDataCollection() {
    this.router.navigateByUrl('/data-collection');
  }

  showSuccess(title, message) {
    this.messageService.add({ key: 'tc', severity: 'success', summary: title, detail: message });
  }

  showInfo(title, message) {
      this.messageService.add({ key: 'tc', severity: 'info', summary: title, detail: message });
  }

  showWarn(title, message) {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: title, detail: message });
  }

  showError(title, message) {
      this.messageService.add({ key: 'tc', severity: 'error', summary: title, detail: message });
  }

  handleError(err) {
    console.log(err);
    this.loading = false;
    return;
  }

}
